@import url("https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap");
@import "./common.scss";

* {
  outline: none;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgb(170, 170, 170) #fff0;

  &::-webkit {
    &-scrollbar {
      width: 5px;
      height: 0;

      &-thumb {
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 6px rgb(170, 170, 170);
        box-shadow: inset 0 0 6px rgb(170, 170, 170);
      }
    }

    &-input-placeholder {
      color: #939393;
    }
  }

  &:-ms-input-placeholder {
    color: #939393;
  }

  &::-moz {
    &-placeholder {
      color: #939393;
    }
    &-focus-inner {
      border: 0;
    }
  }

  &::placeholder {
    color: #939393;
  }

  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-sizing: border-box;
  }
}

body {
  padding: 0;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  color: $color-black;
  background: $color-white;
  font-size: 14px;
  cursor: default;
  width: 100%;
  line-height: 1.5;

  &.isPopup {
    position: fixed;
    padding-right: 5px;
    @include responsive(mobile) {
      padding-right: 0;
    }
  }

  main {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0 auto;

    .page {
      flex: 1;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 0;
}

h1 {
  font-size: 24px;

  @include responsive(mobile) {
    font-size: 20px;
  }
}

a,
label,
b,
strong,
p,
span {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

p,
option {
  opacity: 0.95;
}

a {
  color: $color-black;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.95;
  }
}

button {
  cursor: pointer;

  &:hover {
    opacity: 0.95;
  }
}

b,
strong {
  font-weight: 500;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

ol {
  margin: 0;
  padding: 0;

  li {
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

input,
textarea {
  background: $color-white;
  border: 1px solid $color-border;
  color: $color-black;
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 3px;
  font-weight: 400;
}

textarea {
  padding: 5px;
  min-height: 100px;
  resize: none;
}

select {
  background: transparent;
  box-sizing: border-box;
  /* appearance: none; */
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  cursor: pointer;
  width: 100%;
  height: 40px;
  border: solid 1px $color-border;
  border-radius: 3px;
  padding: 0 5px;
}

.fa {
  color: $color-black;
}

.d-n {
  display: none !important;
}

hr {
  border: 0.5px solid $color-border;
  margin: 15px 0;

  @include responsive(mobile) {
    margin: 10px 0;
  }
}

// Checkbox

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

label {
  position: relative;
}

label.check_list {
  padding-left: 25px;
  font-weight: 400 !important;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  /* background-color: #e7e7e7; */
  border: 2px solid $color-blue;
  background-color: $color-white;
  border-radius: 3px;
}

.checkmark.radio {
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
label:hover input[type="checkbox"] ~ .checkmark,
label:hover input[type="radio"] ~ .checkmark {
  background-color: rgba(0, 112, 165, 0.1);
}

/* When the checkbox is checked, add a blue background */
label input[type="checkbox"]:checked ~ .checkmark {
  background-color: $color-blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 4px;
  top: -2px;
  width: 4px;
  height: 12px;
  border: solid $color-white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

label input[type="radio"]:checked ~ .checkmark {
  background-color: $color-white;
}

label input[type="radio"]:checked ~ .checkmark.radio:before {
  content: " ";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  background: $color-blue;
}

/* checkbox switch */

.check_btn {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check_btn label {
  cursor: pointer;
  height: 26px;
}

.check_switch {
  position: absolute;
  top: 0;
  right: 0;
  height: 26px;
  width: 52px;
  background-color: rgba(51, 102, 153, 0.2);
  border: 2px solid $color-blue;
  border-radius: 50px;
}

/* When the checkbox is checked, add a blue background */
label input[type="checkbox"]:checked ~ .check_switch {
  background-color: $color-blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check_switch:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 3px;
  background-color: $color-white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

/* Show the checkmark when checked */
input[type="checkbox"]:checked ~ .check_switch:before {
  display: block;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

// End

/*--------------------------- End -----------------------------*/

.show-err {
  color: $color-red;
}

button {
  padding: 12px 20px;
  border: none;
  border-radius: 3px;
  letter-spacing: 0.5px;
  background: $color-red;
  color: $color-white;
  white-space: nowrap;

  @include responsive(mobile) {
    padding: 8px 15px;
  }

  i.fa {
    color: $color-white;
  }
}

.t-r {
  text-align: right;
}

.loading {
  margin: 0 auto;
  display: block;
  width: 60px;
  padding: 80px 0;
}

.form-group {
  margin-bottom: 24px;
  @include responsive(mobile) {
    margin-bottom: 15px;
  }

  .DateRangePicker {
    display: block;

    .DateRangePickerInput__withBorder {
      border: 0;
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .DateInput {
        width: unset;
        flex: 0 45%;
      }
    }
  }

  .cols-6 {
    @extend %flex-b-c;

    :first-child,
    :last-child {
      flex: 0 47%;
    }
  }

  &.radio {
    @extend %flex-b-c;
    flex-wrap: wrap;

    label {
      padding-left: 35px;
      margin: 5px 0;
      line-height: 22px;
      font-weight: 400;
      cursor: pointer;
    }
  }

  label {
    margin-bottom: 5px;
    display: block;
    font-weight: 500;
  }
}

.DateRangePicker_picker__portal {
  z-index: 5000;
}

.switch {
  display: inline-flex;
  width: 60px;
  height: 30px;

  input {
    position: unset;

    &:checked + .round {
      background-color: $color-blue;

      &:before {
        -webkit-transform: translateX(29px);
        -ms-transform: translateX(29px);
        transform: translateX(29px);
      }
    }
  }

  .round {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-border;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 23px;
      width: 23px;
      left: 4px;
      bottom: 4px;
      background-color: $color-white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

.table {
  overflow-x: auto;
  border: 1px solid $color-border;

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-top: 1px solid $color-border;
      th {
        background: $color-border;
        text-align: left;
        padding: 10px 5px;
        font-weight: 500;
      }

      td {
        text-align: left;
        padding: 15px 5px;
        @include responsive(mobile) {
          white-space: nowrap;
          padding: 15px;
        }

        i {
          margin-left: 10px;
          cursor: pointer;

          &:hover {
            transform: scale(1.3);
            color: $color-red;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.DateRangePicker_picker table tr td {
  padding: 0;
}

.color-red {
  color: $color-red;
}

*:focus::-webkit-input-placeholder {
  opacity: 0;
} /* Chrome <=56, Safari < 10 */
*:focus:-moz-placeholder {
  opacity: 0;
} /* FF 4-18 */
*:focus::-moz-placeholder {
  opacity: 0;
} /* FF 19-50 */
*:focus:-ms-input-placeholder {
  opacity: 0;
} /* IE 10+ */
*:focus::placeholder {
  opacity: 0;
} /* Modern Browsers */

/* -------------------------------------   CSS   ------------------------------------- */

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: "FontAwesome";
}

.slick-track {
  margin-left: 0;
  margin-right: 0;
}

.slick-arrow {
  // background: $color-white;
  width: 32px;
  height: 32px;
  // border: 1px solid $color-border;
  // border-radius: 50%;
  z-index: 2;
  border-radius: 3px;
  border: 3px solid $color-white;
  background: $bg-e7;

  @include responsive(mobile) {
    display: none !important;
  }

  &.slick-prev {
    left: -16px;
    &:before {
      color: $color-black;
      margin-left: 2px;
      opacity: 0.9;
      font-size: 28px;
      line-height: 25px;
    }
  }

  &.slick-next {
    right: -16px;
    &:before {
      color: $color-black;
      opacity: 0.9;
      font-size: 28px;
      line-height: 14px;
      margin-right: -2px;
    }
  }
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  opacity: 1;
  border: 3px solid $color-white;
  background: $bg-e7;
}

.slick-disabled {
  display: none !important;
}

.slick-prev:before,
[dir="rtl"] .slick-next:before {
  content: "\f104";
}

.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "\f105";
}

.DateRangePicker {
  .DateInput_input {
    font-size: unset;
    background: transparent;
    border: 1px solid #e7e7e7;
    color: #464646;
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 3px;
    font-weight: 400;
  }

  .DateRangePickerInput_arrow_svg {
    fill: $color-black;
    @media only screen and (max-width: 767px) {
      height: 16px;
      width: 16px;
    }
  }
}

.color-red {
  color: $color-red;
}

.bg-grey {
  background: $bg-87;
}

.bg-red {
  background: $color-red;
}

.progress {
  background-color: $bg-f5;
  border-radius: 51px;

  .progress-bar {
    color: $color-white;
    text-align: center;
    background-color: $color-green;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width 0.6s ease;
    animation: progress-bar 2s linear infinite;
    border-radius: 51px;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 40%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 75%,
      rgba(255, 255, 255, 0.15) 50%,
      transparent 75%,
      transparent
    );
    background-size: 15px 10px;
  }

  @keyframes progress-bar {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }
}

a.button,
span.button {
  padding: 12px 20px;
  border: none;
  border-radius: 3px;
  letter-spacing: 0.5px;
  background: $color-red;
  color: $color-white;
  white-space: nowrap;
  display: inline-block;
}
