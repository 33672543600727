$color-red: #ff5959;
$color-green: #019ea6;
$color-blue: #0171a6;
$color-orange: #a65901;
$color-white: #ffffff;
$color-black: #464646;

// Background
$bg-fa: #fafafa;
$bg-fb: #fbfbfb;
$bg-e7: #e7e7e7;
$bg-f5: #f5f5f5;
$bg-f7: #f7f7f7;
$bg-f9: #f9f9f9;
$bg-87: #878787;

// Border
$color-border: #e7e7e7;

$image-path: "/assets/images/";

%flex {
  display: flex;

  &-b {
    display: flex;
    justify-content: space-between;

    &-c {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-c {
    display: flex;
    align-items: center;
  }

  &-jc {
    display: flex;
    justify-content: center;

    &-c {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@mixin responsive($size) {
  @if $size == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size == tablet {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  }
}
